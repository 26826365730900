import React, { Component } from 'react'
import Router from 'next/router'
import nextCookie from 'next-cookies'
import cookie from 'js-cookie'
import jwt from 'jsonwebtoken'

export type JWT = {
  iss: string
  aud: string
  iat: number
  exp: number
  user: User
}

export type User = {
  id: string
  name: string
  email: string
  isAdmin: boolean
  permissions: string[]
}

export const login = async (token: string) => {
  saveToken(token)

  if (!isAdmin(token)) {
    Router.push('/app')
  } else {
    Router.push('/admin')
  }
}

export const saveToken = (token: string) => {
  cookie.set('token', token, { expires: 1 })
}

export const logout = () => {
  cookie.remove('token')
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now().toString())
  Router.push('/login')
}

// Gets the display name of a JSX component for dev tools
const getDisplayName = Component => Component.displayName || Component.name || 'Component'

export const withAuthSync = WrappedComponent =>
  class extends Component {
    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`

    static async getInitialProps(ctx) {
      const token = auth(ctx)

      const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx))

      return { ...componentProps, token }
    }

    constructor(props) {
      super(props)

      this.syncLogout = this.syncLogout.bind(this)
    }

    componentDidMount() {
      window.addEventListener('storage', this.syncLogout)
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.syncLogout)
      window.localStorage.removeItem('logout')
    }

    syncLogout(event) {
      if (event.key === 'logout') {
        console.log('logged out from storage!')
        Router.push('/login')
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

export const auth = ctx => {
  const { token } = nextCookie(ctx)

  /*
   * This happens on server only, ctx.req is available means it's being
   * rendered on server. If we are on server and token is not available,
   * means user is not logged in.
   */
  if (ctx.req && !token) {
    ctx.res.writeHead(302, { Location: '/login' })
    ctx.res.end()
    return
  }

  // We already checked for server. This should only happen on client.
  if (!token) {
    Router.push('/login')
  }

  return token
}

export type WithAdminSyncProps = {
  token: string
  id?: string
}

export const withAdminSync = WrappedComponent =>
  class extends Component {
    static displayName = `withAdminSync(${getDisplayName(WrappedComponent)})`
    static async getInitialProps(ctx) {
      const token = adminAuth(ctx)
      const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx))
      return { ...componentProps, token }
    }
    constructor(props) {
      super(props)
      this.syncLogout = this.syncLogout.bind(this)
    }
    componentDidMount() {
      window.addEventListener('storage', this.syncLogout)
    }
    componentWillUnmount() {
      window.removeEventListener('storage', this.syncLogout)
      window.localStorage.removeItem('logout')
    }
    syncLogout(event) {
      if (event.key === 'logout') {
        console.log('logged out from storage!')
        Router.push('/login')
      }
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

export const adminAuth = ctx => {
  const { token } = nextCookie(ctx)

  /*
   * This happens on server only, ctx.req is available means it's being
   * rendered on server. If we are on server and token is not available,
   * means user is not logged in.
   */
  if (ctx.req && !token) {
    ctx.res.writeHead(302, { Location: '/login' })
    ctx.res.end()
    return
  }
  if (ctx.req && !isAdmin(token)) {
    ctx.res.writeHead(302, { Location: '/app' })
    ctx.res.end()
    return
  }

  // We already checked for server. This should only happen on client.
  if (!token) {
    Router.push('/login')
  }
  if (!isAdmin(token)) {
    Router.push('/app')
  }

  return token
}

export function isAdmin(token) {
  if (!token) {
    return false
  }
  const object = jwt.decode(token) as JWT
  if (!object) return false

  return !!object.user!.isAdmin
}

export function isLoggedIn(token) {
  if (!token) {
    return false
  }
  const object = jwt.decode(token) as JWT
  if (!object) return false

  return object!.user
}

export function getToken(ctx) {
  const { token } = nextCookie(ctx)
  return token || ''
}

export function getUser(ctx) {
  const token = getToken(ctx)
  return (jwt.decode(token) as JWT).user
}
